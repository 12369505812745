import { setValorCelda } from "./UlGeneralTool";

const classHide: string = "w3-hide";
const classShow: string = "w3-show"

export function toggleHide(Element: HTMLElement | undefined) {
	(isHide(Element)) ? showElement(Element) : hideElement(Element)
}
export function toggleVisible(Element: HTMLElement | undefined) { toggleHide(Element) }

export function isHide(Element: HTMLElement | undefined): boolean {
	if (Element) return Element.classList.contains(classHide)
	return true;
}

export function isVisible(Element: HTMLElement | undefined): boolean { return !isHide(Element) }

export function hideElement(Element: HTMLElement | undefined) {
	if (!Element) return;
	if (!Element.classList.contains(classHide))
		Element.classList.add(classHide)
	Element.classList.remove(classShow)
}

export function showElement(Element: HTMLElement | undefined) {
	if (!Element) return;
	Element.classList.remove(classHide)
	if (!Element.classList.contains(classShow))
		Element.classList.add(classShow)
}

export function showLoader() { showElement(document.getElementById("loader")!) }

export function hideLoader() { hideElement(document.getElementById("loader")!) }

export function emptySelect(Element: HTMLSelectElement | undefined) {
	if (!Element) return;
	for (let i = 1; i < Element.options.length; i++) Element.options[i].remove();
	Element.options[0].selected = true;
}

export function activarBtnBack(value: boolean) {
	var element = document.getElementById("back")!;
	if (value) {
		element.classList.add("btnBackDisabled");
		element.classList.remove("w3-button");
	}
	else {
		element.classList.remove("btnBackDisabled");
		element.classList.add("w3-button");
	}
}

export function goToLogin() {
	Office.addin.showAsTaskpane()
}

export function copyToClipboard(str: string): void {
	const el = document.createElement('textarea');
	el.value = str;
	el.setAttribute('readonly', '');
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};


export function EventsItemsTable() {
	let coll = document.getElementsByClassName("datosTabla");
	for (let i = 0; i < coll.length; i++) coll[i].addEventListener("dblclick", function (event) { setValorCelda((event.currentTarget as HTMLElement).id); });
}