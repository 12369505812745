import { prepareEncrypt } from "@ingenieria_insoft/ispclientjs";
import { ISEncrypt, ISRequest, ServiciosInSoft, TIS_IApp, TInfoConnection, TJSONArray, TReqResponse, allTrim, asignarUbicacion, isBool, upperCase, val2JSON, val2KeyIApp, val2Str } from "@ingenieria_insoft/ispgen";
import { SServerProxy, config } from "../lib/UlConst";
import { emptySelect, hideElement, hideLoader, showElement, showLoader } from "../lib/UlDOMtools";
import { datosLogIn, removeItems, saveError, setItems } from "../lib/UlStore";

async function GetUrlSServerAgente(): Promise<string> {
	let ubicacion: TInfoConnection = await asignarUbicacion(val2KeyIApp(TIS_IApp.AppContaExcl), "sserver-agentes" as ServiciosInSoft);
	return `https://${ubicacion.host}/api`;
}

Office.onReady(async () => {
	prepareEncrypt();
	await Excel.run(async (context) => {
		await context.sync();
		try {
			let { email, url, keyagente } = await datosLogIn(false);
			verifiyStatusLogin()

			if (isBool(keyagente) && keyagente > 0 && isBool(url)) showElement(document.getElementById("loginok")!)

			document?.getElementById("btnLogin")?.addEventListener("click", login);
			document?.getElementById("password")?.addEventListener("keyup", async (event) => { if (event.key === "Enter") await login() });
			document?.getElementById("email")?.addEventListener("focusout", async function (this: HTMLInputElement) {
				try {
					let ReqResponse: TReqResponse = await ISRequest.Get(`${await GetUrlSServerAgente()}/Agentes/${this.value}`)
					if (isBool(ReqResponse.responseText)) {
						let JSON: any = val2JSON(ReqResponse.responseText)
						let datos: Array<any> = JSON.respuesta.datos
						let agente: TJSONArray = datos.filter((obj) => obj.itdagente === "ASW")
						setAgentes(agente, url);
					}
				} catch (error) {
					saveError("evtEmail", "", error as Error);
				}
			});

			if (isBool(email)) {
				let ElementEmail: HTMLInputElement = document.getElementById("email") as HTMLInputElement;
				ElementEmail.value = email;
				ElementEmail.dispatchEvent(new Event("focusout"));
			}

		} catch (error) { saveError("recordarUltimoEmail", "", error as Error) }
		finally { hideLoader(); }
	});
});

async function verifiyStatusLogin() {
	let { email, url, keyagente } = await datosLogIn(false);
	if (!isBool(email) || !isBool(url) || !isBool(keyagente) || keyagente == 0) hideElement(document.getElementById("loginok")!)
	setTimeout(verifiyStatusLogin, 100);
}

async function testAgente(iagente: string): Promise<boolean> {
	try {
		let url: string = `${await GetUrlSServerAgente()}/TestAgente/${iagente}`
		let ReqResponse: TReqResponse = await ISRequest.Get(url);
		let JResonse: any = val2JSON(ReqResponse?.responseText, {});
		return isBool(JResonse.respuesta?.datos);
	} catch (error) {
		saveError("testAgente", "", error as Error);
		return false;
	}
}

function setAgentes(array: Array<any>, url?: string) {
	try {
		hideElement(document.getElementById("errorAgente")!);

		let selectobject: HTMLSelectElement = document.getElementById("agentesDisponibles") as HTMLSelectElement;
		emptySelect(selectobject);
		if (array.length > 0) {
			for (let i = 0; i < array.length; i++)
				if (array[i].bdisponible == "T") {
					let option = document.createElement("option");
					option.value = array[i].sip + ":" + array[i].ipuerto;
					option.id = array[i].iagente;
					option.text = array[i].snombre;
					option.selected = (isBool(url)) ? `http://${option.value}` == url : true;
					selectobject.appendChild(option);
				}
		}
		else mostrarMensaje("errorAgente");
	} catch (error) {
		saveError("setAgentes", "", error as Error);
	}
}

async function login() {
	try {
		showLoader();

		let ElementMail: HTMLInputElement = document.getElementById("email") as HTMLInputElement;
		let ElementPass: HTMLInputElement = document.getElementById("password") as HTMLInputElement;

		let password = ISEncrypt.strToMD5(upperCase(allTrim(ElementPass.value)));
		let email = allTrim(ElementMail.value);

		let url = "http://"
		let selectobject: HTMLSelectElement = document.getElementById("agentesDisponibles") as HTMLSelectElement;
		let iagente = "";
		for (let i = 1; i < selectobject.options.length; i++) {
			if (selectobject.options[i].selected && selectobject.options[i].value !== "default") {
				url += selectobject.options[i].value;
				iagente = selectobject[i].id
			}
		}
		if (!await testAgente(iagente)) return mostrarMensaje("conexion");

		if (!isBool(password) || !isBool(email) || !isBool(allTrim(ElementPass.value))) return mostrarMensaje("errorClave");

		const bodyGetAuth = JSON.stringify({
			_parameters: [
				{
					email: email,
					password: password,
					idmaquina: config.idmaquina
				},
				"",
				config.iapp,
				config.idmaquina
			]
		});
		let ReqResponse: TReqResponse = await ISRequest.Post(
			SServerProxy(`${url}/datasnap/rest/TBasicoGeneral/%22GetAuth%22/`),
			bodyGetAuth
		);
		let JResonse: any = val2JSON(ReqResponse.responseText);
		if (JResonse?.result?.at(0)?.encabezado?.resultado == "true")
			return setItems({ keyagente: JResonse.result[0].respuesta.datos.keyagente, url, email }).then(() => {
				showElement(document.getElementById("loginok")!)
				setTimeout(Office.addin.hide, 2500)
				Excel.run((ctx: Excel.RequestContext) => {
					ctx.workbook.worksheets.getActiveWorksheet().calculate(true);
					return ctx.sync();
				})
			});
		await eliminarCacheLogin();
		mostrarMensaje("conexion", val2Str(JResonse?.result?.at(0)?.encabezado?.mensaje, ""));

	} catch (error) { saveError("Login", "", error as Error); }
	finally { hideLoader() }
}

async function eliminarCacheLogin() {
	try {
		mostrarMensaje("errorClave");
		await removeItems(["controlkey", "email", "url"]);
	} catch (error) {
		saveError("eliminarCacheLogin", "", error as Error);
	}
}

function mostrarMensaje(valor: string, detalle?: string) {
	let valores = ["errorClave", "confExito", "conexion", "errorAgente"];
	for (let index = 0; index < valores.length; index++)
		if (valores[index] !== valor)
			hideElement(document.getElementById(valores[index])!)
		else {
			showElement(document.getElementById(valores[index])!)
			document.getElementById("detalleError")!.innerHTML = "<b>Motivo:</b> " + val2Str(detalle);
		}
}